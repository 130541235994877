import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../layouts';
// import Post from '../components/Post';
import Episode from '../components/Episode';
import Sidebar from '../components/Sidebar';
import photo from '../pages/photo.png';

class IndexRoute extends React.Component {
  render() {
    const items = [];
    const { title, subtitle, siteUrl } = this.props.data.site.siteMetadata;
    const pathPrefix = this.props.data.site.pathPrefix;
    // const posts = this.props.data.allMarkdownRemark.edges;
    const episodes = this.props.data.allPodcastFeedItem.edges;
    // posts.forEach((post) => {
    //   items.push(<Post data={post} key={post.node.fields.slug} />);
    // });
    episodes.forEach((episode) => {
      items.push(<Episode data={ {episode, pathPrefix} } key={episode.node.guid} />) 
    }); 

    return (
      <Layout>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={subtitle} />
          <meta property="og:url" content={siteUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={subtitle} />
          <meta property="og:image" content={photo} />
        </Helmet>
        <Sidebar {...this.props} />
        <div className="content">
          <div className="content__inner">
            {items}
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexRoute;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      pathPrefix,
      siteMetadata {
        title
        subtitle
        copyright
        siteUrl
        menu {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          instagram
          facebook
          github
          rss
          ivoox
          itunes
          spotify
          google
        }
      }
    }
    allPodcastFeedItem (limit: 1000) {
      edges {
        node {
          guid,
          title,
          description,
          published,
          link
        }
      }
    }
  }
`;
