import React from 'react';
import Link from 'gatsby-link';
import moment from 'moment';
import linkifyHtml from 'linkifyjs/html';
import slugify from 'slugify';
import './style.scss';

const excerpt = (val) => {
  const theVal = val.replace(/(?:\r\n|\r|\n)/g, '<br />').replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br />');
  if (theVal.length <= 300) {
    return theVal;
  }
  return `${theVal.replace(/^(.{300}[^\s]*).*/, "$1")}...`;
};

class Episode extends React.Component {
  render() {
    
    const { title, description, published } = this.props.data.episode.node;
    const slug = slugify('/'+title, {lower: true, remove: /[$*_+~.()'"!\-:@]/g});
    const desc = excerpt(description);

    return (
      <div className="post">
        <div className="post__meta">
          <time className="post__meta-time" dateTime={moment(published).format('MMMM D, YYYY')}>
            {moment(published).format('DD/MM/YYYY')}
          </time>
          <span className="post__meta-divider" />
        </div>
        <h2 className="post__title">
          <Link className="post__title-link" to={slug}>{title}</Link>
        </h2>
        <p dangerouslySetInnerHTML={{ __html: linkifyHtml(desc).replace(/(?:\r\n|\r|\n)/g, '<br />')}} />
        <Link className="post__readmore" to={slug}>Leer más</Link>
      </div>
    );
  }
}
// <p className="post__description">{description}</p>

export default Episode;
